var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('add-teamleader',{attrs:{"dialog":_vm.dialog},on:{"updated":function (_) {
        _vm.dialog = _;
        _vm.getTeamleaders();
      }}}),_c('v-card',{staticClass:"transparent",attrs:{"loading":_vm.loading,"outlined":""}},[_c('v-card-title',[_c('h1',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.$t('teamleaders')))]),_c('v-spacer'),_c('v-btn',{attrs:{"large":"","color":"secondary"},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_c('v-icon',[_vm._v("mdi-plus-circle")]),_vm._v(" "+_vm._s(_vm.$t('add'))+" "+_vm._s(_vm.$t('teamleader'))+" ")],1)],1),_c('v-card-text',[_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"4"}},[_c('label',{attrs:{"for":"se"}},[_vm._v(_vm._s(_vm.$t('search')))]),_c('v-text-field',{staticClass:"mt-2",attrs:{"hide-detials":"auto","label":_vm.$t('search'),"id":"se","solo":"","loading":_vm.loading,"clearable":"","append-icon":"mdi-magnify","type":"search"},model:{value:(_vm.options.Search),callback:function ($$v) {_vm.$set(_vm.options, "Search", $$v)},expression:"options.Search"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('label',{attrs:{"for":"comp"}},[_vm._v(_vm._s(_vm.$t('select-company')))]),_c('v-autocomplete',{staticClass:"mt-2",attrs:{"items":_vm.companies,"loading":_vm.loading,"item-text":"name","label":_vm.$t('select-company'),"item-value":"id","no-data-text":_vm.$t('no-data'),"solo":"","hide-details":"auto"},on:{"change":function($event){return _vm.getTeamleaders()}},model:{value:(_vm.options.CompanyId),callback:function ($$v) {_vm.$set(_vm.options, "CompanyId", $$v)},expression:"options.CompanyId"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"disable-pagination":"","loading":_vm.loading,"headers":_vm.headers,"items":_vm.teamleaders,"no-data-text":_vm.$t('no-data'),"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.regions",fn:function(ref){
      var item = ref.item;
return _vm._l((item.regions),function(region){return _c('span',{key:region.id},[_vm._v(" "+_vm._s(region.regionName)+", ")])})}},{key:"item.created",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(new Date(item.created)))+" ")]}},{key:"item.phoneNumber",fn:function(ref){
      var item = ref.item;
return [_c('div',{attrs:{"dir":"ltr"}},[_vm._v(" "+_vm._s(item.phoneNumber)+" ")])]}},{key:"item.salary",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$service.formatCurrency(item.salary))+" ")]}},{key:"item.edit",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"primary","text":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}},{key:"item.view",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{attrs:{"text":"","color":item.isActive ? 'error' : 'success'},on:{"click":function($event){return _vm.toggleActivation(item)}}},[_c('v-icon',[_vm._v(" "+_vm._s(item.isActive ? "mdi-close" : "mdi-check")+" ")]),_vm._v(" "+_vm._s(item.isActive ? _vm.$t('deactivate') : _vm.$t('activate'))+" ")],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }