<template>
  <div>
    <add-teamleader
      @updated="
        (_) => {
          dialog = _;
          getTeamleaders();
        }
      "
      :dialog="dialog"
    />
    <v-card class="transparent" :loading="loading" outlined>
      <v-card-title>
        <h1 class="mb-3">{{ $t('teamleaders') }}</h1>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = !dialog" large color="secondary">
          <v-icon>mdi-plus-circle</v-icon>
          {{ $t('add') }} {{ $t('teamleader') }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row class="mt-4">
          <v-col cols="4">
            <label for="se">{{ $t('search') }}</label>
            <v-text-field
              hide-detials="auto"
              class="mt-2"
              :label="$t('search')"
              id="se"
              solo
              :loading="loading"
              clearable
              v-model="options.Search"
              append-icon="mdi-magnify"
              type="search"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <label for="comp">{{ $t('select-company') }}</label>
            <v-autocomplete
              class="mt-2"
              v-model="options.CompanyId"
              :items="companies"
              :loading="loading"
              item-text="name"
              :label="$t('select-company')"
              item-value="id"
              :no-data-text="$t('no-data')"
              solo
              @change="getTeamleaders()"
              hide-details="auto"
            />
          </v-col>

          <v-col cols="12">
            <v-data-table
              disable-pagination
              :loading="loading"
              :headers="headers"
              :items="teamleaders"
              :no-data-text="$t('no-data')"
              :hide-default-footer="true"
            >
              <template v-slot:item.regions="{ item }">
                <span v-for="region in item.regions" :key="region.id">
                  {{ region.regionName }},
                </span>
              </template>
              <template v-slot:item.created="{ item }">
                {{ formatDate(new Date(item.created)) }}
              </template>
              <template v-slot:item.phoneNumber="{ item }">
                <div dir="ltr">
                  {{ item.phoneNumber }}
                </div>
              </template>
              <template v-slot:item.salary="{ item }">
                {{ $service.formatCurrency(item.salary) }} 
              </template>
              <template v-slot:item.edit="{ item }">
                <v-btn small color="primary" text @click="editItem(item)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <template v-slot:item.view="{ item }">
                <!-- <v-btn
                  small
                  color="primary"
                  text
                  :to="{
                    name: 'hr-user',
                    params: { id: item.id, type: 'TeamLeaders' },
                  }"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn> -->
                <div class="d-flex justify-center">
                  <!-- <v-pagination
                    v-model="options.PageIndex"
                    :length="parseInt(total / options.PageSize) + 1"
                  /> -->
                  <v-btn
                    @click="toggleActivation(item)"
                    text
                    :color="item.isActive ? 'error' : 'success'"
                  >
                    <v-icon>
                      {{ item.isActive ? "mdi-close" : "mdi-check" }}
                    </v-icon>
                    {{ item.isActive ? $t('deactivate') : $t('activate') }}
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { formatDate } from "../../../../utils";
import AddTeamleader from "@/views/modals/AddTeamleader";
export default {
  components: {
    AddTeamleader,
  },
  methods: {
    formatDate,
    editItem(item) {
      let temp = item;
      this.$http.get("/teamleaders/" + temp.id).then((res) => {
        this.$store.commit("users/updateItem", res.data.result);
        this.$store.commit("users/toggleEdit");
        this.dialog = true;
      });
    },
    async toggleActivation(item) {
      try {
        this.loading = true;
        await this.$http.delete("/users/toggleActivation/" + item.id);
        this.getTeamleaders();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    getTeamleaders() {
      this.loading = true;
      let filter =
        "?" +
        Object.entries(this.options)
          .map(([key, val]) => {
            if (val !== "" && val !== null) {
              return `${key}=${val}`;
            }
          })
          .join("&");

      this.$http
        .get("/teamleaders" + filter.replace("?&", "?"))
        .then((res) => {
          this.total = res.data.count;
          this.teamleaders = res.data.data;
          this.loading = false;
        })
        .catch((e) => {
          this.$store.commit("UPDATE_SNACKBAR", true);
          this.$store.commit(
            "UPDATE_SNACKBAR_MESSAGE",
            e.response.data.message
          );
        });
    },
  },
  data() {
    return {
      companies: [],
      company: {},
      total: 0,
      options: {
        Search: "",
        CompanyId: "",
        PageIndex: 1,
        PageSize: 10,
      },
     
      headers: [
        {
          text: this.$t('name'),
          align: "start",
          sortable: false,
          value: "fullName",
        },
        {
          text: this.$t('username'),
          sortable: true,
          value: "userName",
        },
        {
          text: this.$t('email'),
          sortable: true,
          value: "email",
        },
        {
          text: this.$t('phone-number'),
          sortable: true,
          value: "phoneNumber",
        },
        {
          text: this.$t('company'),
          sortable: true,
          value: "companyName",
        },
        {
          text: this.$t('type'),
          sortable: true,
          value: "userTypeName",
        },
        {
          text: this.$t('salary'),
          sortable: true,
          value: "salary",
        },
        {
          text: this.$t('created'),
          sortable: true,
          value: "created",
        },
        {
          text: this.$t('edit'),
          sortable: true,
          value: "edit",
        },
        // {
        //   text: "More",
        //   sortable: false,
        //   value: "view",
        // },
      ],
      loading: true,
      teamleaders: [],
      dialog: false,
    };
  },
  created() {
    this.$http
      .get("/Companies", {
        params: {
          OfficeId: this.$store.state.userInfo.office.id,
        },
      })
      .then((res) => {
        this.companies = res.data.result;
        this.company = this.companies[0];
        this.options.CompanyId = this.$store.state.company.id;
        this.getTeamleaders();
      })
      .catch((e) => {
        this.$store.commit("UPDATE_SNACKBAR", true);
        this.$store.commit("UPDATE_SNACKBAR_MESSAGE", e.response.data.message);
      });
  },
  watch: {
    options: {
      handler: function () {
        this.getTeamleaders();
      },
      deep: true,
    },
  },
};
</script>
