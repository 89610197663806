<template>
  <v-dialog persistent v-model="dialog" width="800">
    <v-card outlined :loading="loading">
      <v-card-title class="text-h5 my-3"
        >{{ isEdit ? $t('edit') : $t('add') }} {{ $t('representatives') }}
        <v-spacer></v-spacer>
        <v-btn @click="dialog = !dialog" text color="error">
          <f-icon icon="times-circle" />
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-10">
        <v-form :loading="loading">
          <v-row>
            <v-col v-if="!isEdit" cols="12">
              <v-select
                v-model="inputs.representative.companyId"
                :label="$t('company')"
                outlined
                :items="companies"
                item-text="name"
                :disabled="loading"
                @change="
                  getSalePointsAndTeamLeaders(inputs.representative.companyId)
                "
                item-value="id"
              ></v-select>
            </v-col>

            <v-col cols="12">
              <v-form ref="addRepresentative" :disabled="disabled">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-select
                      v-model="inputs.representativeTypeId"
                      :label="$t('type')"
                      outlined
                      :items="types"
                      item-text="name"
                      item-value="id"
                      :rules="[(v) => !!v || $t('this-field-is-requird')]"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      hide-detials="auto"
                      v-model="inputs.userName"
                      :label="$t('username')"
                      outlined
                      :rules="[(v) => !!v || $t('this-field-is-requird')]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      hide-detials="auto"
                      v-model="inputs.fullName"
                      label="Full name"
                      outlined
                      :rules="[(v) => !!v || $t('this-field-is-requird')]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      hide-detials="auto"
                      v-model="inputs.email"
                      :label="$t('email')"
                      outlined
                      type="email"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      hide-detials="auto"
                      v-model="inputs.phoneNumber"
                      :label="$t('phone-number-0')"
                      dir="ltr"
                      v-mask="'+964 ### ### ####'"
                      :rules="[(v) => !!v || $t('this-field-is-requird')]"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col v-if="!isEdit" cols="6">
                    <v-select
                      v-model="inputs.representative.teamLeaderId"
                      :label="$t('teamleader')"
                      outlined
                      :disabled="
                        inputs.representative.companyId == '' || loading
                      "
                      :items="teamLeaders"
                      item-text="fullName"
                      item-value="id"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" v-if="!isEdit" md="6">
                    <v-text-field
                      hide-detials="auto"
                      outlined
                      v-model="inputs.password"
                      :type="isPasswordShow ? 'text' : 'password'"
                      :append-icon="isPasswordShow ? 'mdi-eye-off' : 'mdi-eye'"
                      @click:append="isPasswordShow = !isPasswordShow"
                      :label="$t('password')"
                      :rules="[(v) => !!v || $t('this-field-is-requird')]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      hide-detials="auto"
                      v-model="inputs.salary"
                      :label="$t('salary')"
                      :rules="[(v) => !!v || $t('this-field-is-requird')]"
                      outlined
                      @input="
                        inputs.salary = $service.formatCurrency(inputs.salary)
                      "
                      placeholder="0.0"
                      :pattern="/^-?\d+\.?\d*$/"
                      suffix="IQD"
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col v-if="!isEdit" cols="12" md="12">
                    <v-row
                      align="center"
                      justify="start"
                      class="d-flex align-center"
                      v-for="(attach, i) in files"
                      :key="i"
                    >
                      <v-col cols="5">
                        <v-file-input
                          v-model="files[i].path"
                          label="Attachement File"
                          chips
                          outlined
                          :disabled="filesLoading"
                          :loading="filesLoading"
                        ></v-file-input>
                      </v-col>
                      <v-col cols="5">
                        <v-text-field
                          hide-detials="auto"
                          v-model="files[i].name"
                          label="Attachement Name"
                          outlined
                          :disabled="filesLoading"
                          :loading="filesLoading"
                        ></v-text-field>
                      </v-col>
                      <v-col class="" cols="2">
                        <div class="d-flex mb-6">
                          <v-btn
                            @click="addToList"
                            :disabled="filesLoading"
                            :loading="filesLoading"
                            color="primary"
                            text
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                          <v-btn
                            v-if="i > 0"
                            @click="removeFromList(i)"
                            color="error"
                            text
                            :disabled="filesLoading"
                            :loading="filesLoading"
                          >
                            <f-icon icon="times-circle" />
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col> -->
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>
      <div class="d-flex align-center justify-end py-3 px-3">
        <v-btn color="error" class="mx-1" outlined @click="dialog = !dialog">
          <f-icon icon="times-circle" />
          {{ $t('cancel') }}</v-btn
        >
        <v-btn color="primary" :loading="loading" @click="addRepresentative">
          <v-icon>mdi-plus</v-icon>
          {{ $t('save') }}</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  computed: {
    isEdit() {
      return this.$store.state.users.isEdit;
    },
    item: {
      get() {
        return this.$store.state.users.item;
      },
    },
    disabled() {
      if (this.inputs.representative === undefined) {
        return false;
      } else {
        if (this.inputs.representative.companyId === "") {
          return true;
        }
      }
      return false;
    },
  },
  data() {
    return {
      loading: true,
      companies: [],
      types: [],
      filesLoading: false,
      files: [],
      isPasswordShow: false,
      inputs: {
        fullName: "",
        userName: "",
        email: "",
        password: "",
        phoneNumber: "",
        enrolledDate: "2021-07-08T11:27:19.453Z",
        salary: 0,
        representativeTypeId: "",
        salePoints: [],
        representative: {
          companyId: "",
          teamLeaderId: "",
        },
        // userAttachement: [
        //   {
        //     name: "",
        //     path: null,
        //     type: "",
        //   },
        // ],
      },
      salePoints: [],
      teamLeaders: [],
    };
  },
  created() {
    this.$http
      .get("/representatives/types")
      .then((res) => {
        this.types = res.data.result;
        this.$http
          .get("/Companies", {
            params: {
              OfficeId: this.$store.state.userInfo.office.id,
            },
          })
          .then((com) => {
            this.companies = com.data.result;
            this.loading = false;
            // this.checkIsEdit();
          });
      })
      .catch(() => {
        this.$store.commit("UPDATE_SNACKBAR", true);
      });
  },
  props: ["dialog"],
  methods: {
    addToList() {
      this.files.push({
        name: "",
        path: null,
        type: "",
      });
    },
    removeFromList(index) {
      this.files = this.files.splice(index, 1);
    },
    getSalePointsAndTeamLeaders(id) {
      this.loading = true;
      this.$http.get("/SalePoints?CompanyId=" + id).then((res) => {
        this.salePoints = res.data.data;
        this.$http.get("/TeamLeaders?CompanyId=" + id).then((tem) => {
          this.teamLeaders = tem.data.data;
          this.loading = false;
        });
      });
    },
    async addRepresentative() {
      var val = this.$refs.addRepresentative.validate();
      if (val) {
        this.inputs.salary = this.inputs.salary.toString().replace(/,/g, "");
        this.loading = true;
        if (!this.isEdit) {
          // if (this.files[0].path != null) {
          //   this.filesLoading = true;
          //   var form = new FormData();
          //   this.files.forEach((file) => {
          //     form.append("files", file.path);
          //   });
          //   this.inputs.userAttachement = [];
          //   var img = await this.$http.post(
          //     "/UploadFile/postUploadFiles",
          //     form
          //   );
          //   console.log(img);
          //   img.data.result.fileList.forEach((a, i) => {
          //     this.inputs.userAttachement.push({
          //       path: a.path,
          //       type: "attachment" + i,
          //     });
          //   });
          // }
          for (let i = 0; i < this.inputs.salePoints.length; i++) {
            this.inputs.salePoints[i] = {
              id: this.inputs.salePoints[i],
            };
          }
          this.$http
            .post("/representatives/post", this.inputs)
            .then((res) => {
              if (res.data.statusCode == 201) {
                this.dialog = false;
              }
            })
            .catch((e) => {
              this.$store.commit("UPDATE_SNACKBAR", true);
              this.$store.commit(
                "UPDATE_SNACKBAR_MESSAGE",
                e.response.data.message +
                  "<br />" +
                  typeof e.response.data.errors ==
                  "array"
                  ? e.response.data.errors.join(" ")
                  : ""
              );

              this.loading = false;
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.$http
            .put("/representatives/put/" + this.item.id, this.inputs)
            .then(() => {
              this.dialog = false;
            })
            .catch((e) => {
              this.$store.commit("UPDATE_SNACKBAR", true);
              this.$store.commit(
                "UPDATE_SNACKBAR_MESSAGE",
                e.response.data.message +
                  "<br />" +
                  typeof e.response.data.errors ==
                  "array"
                  ? e.response.data.errors.join(" ")
                  : ""
              );
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },
  },
  watch: {
    "inputs.companyId"() {
      this.disableTypesForCompany();
    },
    dialog() {
      if (this.dialog == false) {
        if (this.$store.state.users.isEdit) {
          this.addToList();
          this.$store.commit("users/toggleEdit");
        } else {
          this.$refs.addRepresentative.reset();

          // this.addToList();
        }
      } else {
        if (!this.$store.state.users.isEdit) {
          this.$refs.addRepresentative.reset();
        }
      }
      if (this.isEdit) {
        this.inputs = this.item;
        if (this.item.imageUrl != null) {
          let fileName = this.item.imageUrl.split("_");
          fileName = fileName[fileName.length - 1];
          this.file = new File(["1"], fileName);
        } else {
          this.file = null;
        }
        this.inputs.userTypeId = this.item.userType.id;
        this.inputs.representativeTypeId = this.item.userTypeId;
        this.inputs.representative = {};
        this.inputs.representative.companyId = "this.item.company.id";
        this.inputs.newSalePoints = [];
        this.item.salePoints.forEach((salePoint) => {
          this.inputs.newRegionsForTeamLeader.push({
            id: salePoint.salePointId,
          });
        });
      }
      this.$emit("updated", this.dialog);

      this.loading = false;
      this.filesLoading = false;
      this.files = [];
      this.addToList();
    },
  },
};
</script>
<style scoped></style>
