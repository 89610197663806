<template>
  <v-dialog persistent v-model="dialog" width="900">
    <v-card outlined :loading="loading">
      <v-card-title class="text-h5 my-3">
        {{ isEdit ? $t('edit') : $t('add') }} {{ $t('teamleader') }}
        <v-spacer></v-spacer>
        <v-btn @click="dialog = !dialog" text color="error">
          <f-icon icon="times-circle" />
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-10">
        <v-form :loading="loading">
          <v-row>
            <v-col v-if="!isEdit" cols="12">
              <v-select
                v-if="!isEdit"
                v-model="inputs.teamleader.companyId"
                :label="$t('company')"
                outlined
                :items="companies"
                item-text="name"
                :disabled="loading"
                item-value="id"
                @change="
                  () => {
                    getSupervisors(inputs.teamleader.companyId);
                  }
                "
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-form ref="addteamleader">
                <v-row>
                  <v-col cols="12" v-if="!isEdit" md="6">
                    <v-select
                      v-if="!isEdit"
                      v-model="inputs.teamleader.supervisorId"
                      :label="$t('supervisors')"
                      outlined
                      :items="types"
                      item-text="fullName"
                      item-value="id"
                      :rules="[(v) => !!v || $t('this-field-is-requird')]"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" :md="!isEdit ? 6 : 12">
                    <v-select
                      v-model="inputs.teamLeaderTypeId"
                      :label="$t('type')"
                      outlined
                      :items="teamleaderst"
                      item-text="name"
                      item-value="id"
                      :rules="[(v) => !!v || $t('this-field-is-requird')]"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      hide-detials="auto"
                      v-model="inputs.fullName"
                      :label="$t('name')"
                      outlined
                      :rules="[(v) => !!v || $t('this-field-is-requird')]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      hide-detials="auto"
                      v-model="inputs.email"
                      :label="$t('email')"
                      outlined
                      type="email"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      hide-detials="auto"
                      v-model="inputs.userName"
                      :label="$t('username')"
                      outlined
                      :rules="[(v) => !!v || $t('this-field-is-requird')]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      hide-detials="auto"
                      v-model="inputs.phoneNumber"
                      :label="$t('phone-number-0')"
                      dir="ltr"
                      v-mask="'+964 ### ### ####'"
                      :rules="[(v) => !!v || $t('this-field-is-requird')]"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" v-if="!isEdit" md="6">
                    <v-text-field
                      hide-detials="auto"
                      outlined
                      v-model="inputs.password"
                      :type="isPasswordShow ? 'text' : 'password'"
                      :append-icon="isPasswordShow ? 'mdi-eye-off' : 'mdi-eye'"
                      @click:append="isPasswordShow = !isPasswordShow"
                      :label="$t('password')"
                      :rules="[(v) => !!v || $t('this-field-is-requird')]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      hide-detials="auto"
                      v-model="inputs.salary"
                      :label="$t('salary')"
                      :rules="[(v) => !!v || $t('this-field-is-requird')]"
                      outlined
                      @input="
                        inputs.salary = $service.formatCurrency(inputs.salary)
                      "
                      placeholder="0.0"
                      :pattern="/^-?\d+\.?\d*$/"
                      suffix="IQD"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" v-if="!isEdit" md="6">
                    <v-select
                      label="Regions"
                      :loading="loading"
                      :items="regions"
                      outlined
                      multiple
                      chips
                      item-text="name"
                      item-value="id"
                      v-model="inputs.regionsForTeamLeader"
                    ></v-select>
                  </v-col>
                  <!-- <v-col cols="12" md="12">
                    <v-row
                      align="center"
                      justify="start"
                      class="d-flex align-center"
                      v-for="(attach, i) in files"
                      :key="i"
                    >
                      <v-col cols="5">
                        <v-file-input
                          v-model="files[i].path"
                          label="Attachement File"
                          chips
                          outlined
                          :disabled="filesLoading"
                          :loading="filesLoading"
                        ></v-file-input>
                      </v-col>
                      <v-col cols="5">
                        <v-text-field
                          hide-detials="auto"
                          v-model="files[i].name"
                          label="Attachement Name"
                          outlined
                          :disabled="filesLoading"
                          :loading="filesLoading"
                        ></v-text-field>
                      </v-col>
                      <v-col class="" cols="2">
                        <div class="d-flex mb-6">
                          <v-btn
                            @click="addToList"
                            :disabled="filesLoading"
                            :loading="filesLoading"
                            color="primary"
                            text
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                          <v-btn
                            v-if="i > 0"
                            @click="removeFromList(i)"
                            color="error"
                            text
                            :disabled="filesLoading"
                            :loading="filesLoading"
                          >
                            <f-icon icon="times-circle" />
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col> -->
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <div class="d-flex align-center justify-end py-3 px-3">
        <v-btn color="error" class="mx-1" outlined @click="dialog = !dialog">
          <f-icon icon="times-circle" />
          {{ $t('cancel') }}</v-btn
        >
        <v-btn color="primary" :loading="loading" @click="addteamleader">
          <v-icon>mdi-plus</v-icon>
          {{ $t('save') }}</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  computed: {
    isEdit() {
      return this.$store.state.users.isEdit;
    },
    item: {
      get() {
        return this.$store.state.users.item;
      },
    },
    disabled() {
      if (this.inputs.teamleader === undefined) {
        return false;
      } else {
        if (this.inputs.teamleader.companyId === "") {
          return true;
        }
      }
      return false;
    },
  },
  data() {
    return {
      regions: [],
      loading: true,
      companies: [],
      types: [],
      files: [],
      teamleaderst: [],
      filesLoading: false,
      isPasswordShow: false,
      inputs: {
        fullName: "",
        userName: "",
        email: "",
        teamLeaderTypeId: "",
        password: "",
        phoneNumber: "",
        enrolledDate: "2021-07-07T10:20:43.442Z",
        salary: 0,
        regionsForTeamLeader: [],
        // userAttachement: [
        //   {
        //     name: "",
        //     path: null,
        //     type: "",
        //   },
        // ],
        teamleader: {
          companyId: "",
          supervisorId: "",
        },
      },
    };
  },
  created() {
    this.$http
      .get("/Companies", {
        params: {
          OfficeId: this.$store.state.userInfo.office.id,
        },
      })
      .then((com) => {
        this.companies = com.data.result;
        this.$http("/regions").then((res) => {
          this.regions = res.data.result.data;
          this.$http.get("/teamleaders/types").then((res2) => {
            this.teamleaderst = res2.data.result;
            this.loading = false;
          });
        });
        // this.checkIsEdit();
      })
      .catch(() => {
        this.$store.commit("UPDATE_SNACKBAR", true);
      });
  },
  props: ["dialog"],
  methods: {
    addToList() {
      this.files.push({
        name: "",
        path: null,
        type: "",
      });
    },
    removeFromList(index) {
      this.files = this.files.splice(index, 1);
    },
    getSupervisors(id) {
      this.loading = true;
      this.$http.get("/Supervisors/?CompanyId=" + id).then((res) => {
        this.types = res.data.data;
        this.loading = false;
      });
    },
    async addteamleader() {
      var val = this.$refs.addteamleader.validate();
      if (val) {
        var body = this.inputs;

        body.salary = body.salary.toString().replace(/,/g, "");

        this.loading = true;
        if (!this.isEdit) {
          body.regionsForTeamLeader = [];
          for (let i = 0; i < body.regionsForTeamLeader.length; i++) {
            body.regionsForTeamLeader[i] = {
              id: body.regionsForTeamLeader[i],
            };
          }

          // if (this.files[0].path != null) {
          //   this.filesLoading = true;
          //   var form = new FormData();
          //   this.files.forEach((file) => {
          //     form.append("files", file.path);
          //   });
          //   body.userAttachement = [];
          //   var img = await this.$http.post(
          //     "/UploadFile/postUploadFiles",
          //     form
          //   );
          //   console.log(img);
          //   img.data.result.fileList.forEach((a, i) => {
          //     body.userAttachement.push({
          //       path: a.path,
          //       type: "attachment" + i,
          //     });
          //   });
          // }
          this.$http
            .post("/teamleaders/post", body)
            .then((res) => {
              if (res.data.statusCode == 201) {
                this.dialog = false;
              }
            })
            .catch((e) => {
              this.$store.commit("UPDATE_SNACKBAR", true);
              this.$store.commit(
                "UPDATE_SNACKBAR_MESSAGE",
                e.response.data.message +
                  "<br />" +
                  typeof e.response.data.errors ==
                  "array"
                  ? e.response.data.errors.join(" ")
                  : ""
              );
              this.loading = false;
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.$http
            .put("/teamleaders/put/" + this.item.id, body)
            .then(() => {
              this.dialog = false;
            })
            .catch((e) => {
              this.$store.commit("UPDATE_SNACKBAR", true);
              this.$store.commit(
                "UPDATE_SNACKBAR_MESSAGE",
                e.response.data.message +
                  "<br />" +
                  typeof e.response.data.errors ==
                  "array"
                  ? e.response.data.errors.join(" ")
                  : ""
              );
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },
  },
  watch: {
    dialog() {
      try {
        if (this.dialog == false) {
          if (this.$store.state.users.isEdit) {
            this.$store.commit("users/toggleEdit");
          } else {
            this.$refs.addteamleader.reset();
          }
        } else {
          if (!this.$store.state.users.isEdit) {
            this.$refs.addteamleader.reset();
          }
        }
        if (this.isEdit) {
          this.inputs = this.item;
          if (this.item.imageUrl != null) {
            let fileName = this.item.imageUrl.split("_");
            fileName = fileName[fileName.length - 1];
            this.file = new File(["1"], fileName);
          } else {
            this.file = null;
          }
          this.inputs.userTypeId = this.item.userType.id;
          this.inputs.teamLeaderTypeId = this.item.userTypeId;
          this.inputs.teamleader = {};
          this.inputs.teamleader.companyId = this.item.company.id;
          this.inputs.newRegionsForTeamLeader = [];
          this.item.regions.forEach((region) => {
            this.inputs.newRegionsForTeamLeader.push({
              id: region.regionId,
            });
          });
        }
      } catch (e) {
        console.log(e);
      }
      this.$emit("updated", this.dialog);
      this.loading = false;
      this.filesLoading = false;
      this.files = [];
      this.addToList();
    },
  },
};
</script>
<style scoped>
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  margin: 0px !important;
}
.v-input__slot {
  margin-bottom: 0px !important;
  padding: 0px !important;
}
</style>
