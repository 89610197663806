<template>
  <div>
    <add-trainer
      @updated="
        (_) => {
          dialog = _;
          getTrainer();
        }
      "
      :dialog="dialog"
    />
    <v-card :loading="loading" outlined class="transparent">
      <v-card-title>
        <h1 class="mb-3">{{ $t("trainer") }}</h1>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = !dialog" large color="secondary">
          <v-icon>mdi-plus-circle</v-icon>
          {{ $t("add") }} {{ $t("trainer") }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row class="mt-4">
          <v-col cols="4">
            <label for="se">{{ $t("search") }}</label>
            <v-text-field
              hide-detials="auto"
              class="mt-2"
              :label="$t('search')"
              id="se"
              solo
              :loading="loading"
              clearable
              v-model="options.Search"
              append-icon="mdi-magnify"
              type="search"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-data-table
              disable-pagination
              :loading="loading"
              :headers="headers"
              :items="trainer"
              :no-data-text="$t('no-data')"
              :hide-default-footer="true"
            >
              <template v-slot:item.phoneNumber="{ item }">
                <div dir="ltr">
                  {{ item.phoneNumber }}
                </div>
              </template>
              <template v-slot:item.salary="{ item }">
                {{ $service.formatCurrency(item.salary) }}
              </template>
              <template v-slot:item.created="{ item }">
                <span class="text-no-wrap">
                  {{ formatDate(new Date(item.created)) }}
                </span>
              </template>
              <template v-slot:item.view="{ item }">
                <v-btn
                  small
                  color="primary"
                  text
                  :to="{
                    name: 'hr-user',
                    params: { id: item.id, type: 'Trainer' },
                  }"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
            </v-data-table>
            <div class="d-flex justify-center">
              <v-pagination
                v-model="options.PageIndex"
                :length="parseInt(total / options.PageSize) + 1"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { formatDate } from "../../../../utils";
import AddTrainer from "@/views/modals/AddTrainer.vue";

export default {
  components: {
    AddTrainer,
  },
  methods: {
    formatDate,
    getTrainer() {
      this.loading = true;
      let filter = Object.entries(this.options)
        .map(([key, val]) => {
          if (val !== "" && val !== null) {
            return `${key}=${val}`;
          }
        })
        .join("&");
      this.$http
        .get("/users?" + filter)
        .then((res) => {
          this.total = res.data.count;
          this.trainer = res.data.data;
          this.loading = false;
        })
        .catch((e) => {
          this.$store.commit("UPDATE_SNACKBAR", true);
          this.$store.commit(
            "UPDATE_SNACKBAR_MESSAGE",
            e.response.data.message
          );
        });
    },
  },
  data() {
    return {
      companies: [],
      company: {},
      options: {
        Search: "",
        OfficeId: this.$store.state.userInfo.office.id,
        UserTypes: "Tr",
        PageIndex: 1,
        PageSize: 10,
      },
      total: 0,
      headers: [
        {
          text: this.$t('name'),
          align: "start",
          sortable: false,
          value: "fullName",
        },
        {
          text: this.$t('username'),
          sortable: true,
          value: "userName",
        },
        {
          text: this.$t('email'),
          sortable: true,
          value: "email",
        },
        {
          text: this.$t('phone-number'),
          sortable: true,
          value: "phoneNumber",
        },
        {
          text: this.$t('company'),
          sortable: true,
          value: "companyName",
        },
        {
          text: this.$t('type'),
          sortable: true,
          value: "userTypeName",
        },
        {
          text: this.$t('salary'),
          sortable: true,
          value: "salary",
        },
        {
          text: this.$t('created'),
          sortable: true,
          value: "created",
        },
        {
          text: this.$t('edit'),
          sortable: true,
          value: "edit",
        },
        // {
        //   text: "More",
        //   sortable: false,
        //   value: "view",
        // },
      ],
      loading: true,
      trainer: [],
      trainerTypes: [],
      dialog: false,
    };
  },
  created() {
    this.getTrainer();
  },
  watch: {
    options: {
      handler: function() {
        this.getTrainer();
      },
      deep: true,
    },
  },
};
</script>
