var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Agent',{attrs:{"dialog":_vm.dialog},on:{"updated":function (_) {
        _vm.dialog = _;
        _vm.getRepresentatives();
      }}}),_c('v-card',{staticClass:"transparent",attrs:{"loading":_vm.loading,"outlined":""}},[_c('v-card-title',[_c('h1',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.$t('representatives')))]),_c('v-spacer'),_c('v-btn',{attrs:{"large":"","color":"secondary"},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_c('v-icon',[_vm._v("mdi-plus-circle")]),_vm._v(" "+_vm._s(_vm.$t('add'))+" "+_vm._s(_vm.$t('representatives'))+" ")],1)],1),_c('v-card-text',[_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"4"}},[_c('label',{attrs:{"for":"se"}},[_vm._v(_vm._s(_vm.$t('search')))]),_c('v-text-field',{staticClass:"mt-2",attrs:{"hide-detials":"auto","label":_vm.$t('search'),"id":"se","solo":"","loading":_vm.loading,"clearable":"","append-icon":"mdi-magnify","type":"search"},model:{value:(_vm.options.Search),callback:function ($$v) {_vm.$set(_vm.options, "Search", $$v)},expression:"options.Search"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('label',{attrs:{"for":"comp"}},[_vm._v(_vm._s(_vm.$t('select-company')))]),_c('v-autocomplete',{staticClass:"mt-2",attrs:{"items":_vm.companies,"loading":_vm.loading,"item-text":"name","label":_vm.$t('select-company'),"item-value":"id","no-data-text":_vm.$t('no-data'),"solo":"","hide-details":"auto"},on:{"change":function () {
                _vm.options.CompanyId = _vm.company.id;
              }},model:{value:(_vm.options.CompanyId),callback:function ($$v) {_vm.$set(_vm.options, "CompanyId", $$v)},expression:"options.CompanyId"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('label',{attrs:{"for":"comp"}},[_vm._v(_vm._s(_vm.$t('representative-type')))]),_c('v-select',{staticClass:"mt-2",attrs:{"items":_vm.representativesTypes,"loading":_vm.loading,"item-text":"name","item-value":"id","no-data-text":_vm.$t('no-data'),"solo":"","hide-details":"auto"},on:{"change":function () {
                _vm.getRepresentatives();
              }},model:{value:(_vm.options.RepresentativeTypeId),callback:function ($$v) {_vm.$set(_vm.options, "RepresentativeTypeId", $$v)},expression:"options.RepresentativeTypeId"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"disable-pagination":"","loading":_vm.loading,"headers":_vm.headers,"items":_vm.representatives,"no-data-text":_vm.$t('no-data'),"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.phoneNumber",fn:function(ref){
              var item = ref.item;
return [_c('div',{attrs:{"dir":"ltr"}},[_vm._v(" "+_vm._s(item.phoneNumber)+" ")])]}},{key:"item.salary",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$service.formatCurrency(item.salary))+" ")]}},{key:"item.created",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.formatDate(new Date(item.created)))+" ")])]}},{key:"item.view",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"primary","text":"","to":{
                  name: 'hr-user',
                  params: { id: item.id, type: 'Representatives' },
                }}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}},{key:"item.edit",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"primary","text":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"text":"","color":item.isActive ? 'error' : 'success'},on:{"click":function($event){return _vm.toggleActivation(item)}}},[_c('v-icon',[_vm._v(" "+_vm._s(item.isActive ? "mdi-close" : "mdi-check")+" ")]),_vm._v(" "+_vm._s(item.isActive ? _vm.$t('deactivate') : _vm.$t('activate'))+" ")],1)]}}])}),_c('div',{staticClass:"d-flex justify-center"},[_c('v-pagination',{attrs:{"length":parseInt(_vm.total / _vm.options.PageSize) + 1},model:{value:(_vm.options.PageIndex),callback:function ($$v) {_vm.$set(_vm.options, "PageIndex", $$v)},expression:"options.PageIndex"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }