<template>
  <div>
    <drawer :title="$t('human-resources')" :items="drawer.items" icon="hr"> </drawer>
    <!-- use agents -->
    <agents v-if="tab == 'agents'" />
    <!-- use managers -->
    <managers v-if="tab == 'managers'" />
    <!-- use teamleaders -->
    <team-leaders v-if="tab == 'teamleader'" />
    <!-- use supervisors -->
    <supervisors v-if="tab == 'supervisor'" />
    
    <!-- use delivery -->
    <delivery v-if="tab == 'delivery'" />
    
    <!-- use service -->
    <service v-if="tab == 'service'" />
    
    <!-- use trainer -->
    <trainer v-if="tab == 'trainer'" />
    <!-- use accounts -->
    <accounts v-if="tab == 'accounts'" />
    <!-- use salaries -->
    <salaries v-if="tab == 'salaries'" />
    <!-- use incentives -->
    <incentives v-if="tab == 'incentives'" />
    <!-- use deductions -->
    <deductions v-if="tab == 'deductions'" />
    <!-- use Financial -->
    <financial v-if="tab == 'financial'" />
    <!-- use paid -->
    <paid v-if="tab == 'paid'" />
  </div>
</template>
<script>
import Managers from "./users-info/Mangers.vue";
import Supervisors from "./users-info/Supervisors.vue";
import Agents from "./users-info/Agents";
import Delivery from "./users-info/Delivery";
import Service from "./users-info/Service";
import Trainer from "./users-info/Trainer";
import TeamLeaders from "./users-info/TeamLeaders.vue";
import Accounts from "./users-info/Accounts.vue";
import Drawer from "@/layouts/Drawer";
import Salaries from "../accounts/stipend/salaries/Salaries.vue";
import Incentives from "../accounts/stipend/incentives/Incentives.vue";
// import stipend/deductions component
import Deductions from "../accounts/stipend/deductions/Deductions.vue";
// import stipend/financial component
import Financial from "../accounts/stipend/financial/Financial.vue";
import Paid from "../accounts/stipend/Paid.vue";

export default {
  components: {
    Agents,
    Managers,
    Supervisors,
    TeamLeaders,
    Accounts,
    Delivery,
    Service,
    Trainer,
    Drawer,
    Salaries,
    Incentives,
    Deductions,
    Financial,
    Paid,
  },
  watch: {
    $route() {
      var find = this.types.find((v) => v.route == this.$route.params.tab);
      this.activeType = this.types.indexOf(find);
    },
  },
  data() {
    return {
      drawer: {
        items: [
          {
            title: this.$t('managers'),
            icon: "",
            route: "hr-managers",
            access: this.$service.hideFrom((v) => [v == 1])
          },
          {
            title: this.$t('supervisors'),
            icon: "",
            route: "hr-supervisor",
          },

          {
            title: this.$t('teamleaders'),
            icon: "",
            route: "hr-teamleader",
          },
          {
          title: this.$t('representatives'),
            icon: "",
            route: "hr-agents",
          },
          {
            title: this.$t('accounts'),
            icon: "",
            route: "hr-accounts",
            access: this.$service.hideFrom((v) => [v == 1])
          },
          {
            title: this.$t('delivery'),
            icon: "",
            route: "hr-delivery",
            access: this.$service.hideFrom((v) => [v == 1])
          },
          {
            title: this.$t('service'),
            icon: "",
            route: "hr-service",
            access: this.$service.hideFrom((v) => [v == 1])
          },
          {
          title: this.$t('trainer'),
            icon: "",
            route: "hr-trainer",
            access: this.$service.hideFrom((v) => [v == 1])
          },
          {
            title: this.$t('salaries'),
            icon: "mdi-cash-multiple",
            children: [
              {
                title: this.$t('salaries'),
                route: "accounts-salaries",
              },
              {
                title: this.$t('incentives'),
                route: "accounts-incentives",
              },
              {
                title: this.$t('deductions'),
                route: "accounts-deductions",
              },
            ],
          },
        ],
      },
      companies: [],
      activeType: 0,
      types: [
        { route: "managers", text: "dashboard-man" },
        { route: "supervisors", text: "المشرفين" },
        { route: "agents", text: "المندوبين" },
        {
          text: "الموجهين",
          route: "teamleaders",
        },
      ],
      company: {},
      options: {
        Search: "",
        CompanyId: "",
      },
    };
  },
  computed: {
    tab() {
      return this.$route.path.split("/")[2];
    },
  },
  created() {
    if (this.tab == undefined || this.tab == "") {
      this.$router.push({
        name: this.drawer.items.filter((v) => v.access == true || v.access == undefined)[0].route,
      });
    }
  },
  beforeMount() {
    this.$store.commit("TOGGLE_DRAWER", true);
  },
};
</script>
<style scoped>
.v-tab {
  letter-spacing: 0;
  font-weight: bolder;
}
</style>