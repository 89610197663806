<template>
  <v-dialog persistent v-model="dialog" width="800">
    <v-card outlined :loading="loading">
      <v-card-title class="text-h5 my-3">
        {{ $t('add') }} {{ $t('trainer') }}

        <v-spacer></v-spacer>
        <v-btn @click="dialog = !dialog" text color="error">
          <f-icon icon="times-circle" />
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-10">
        <v-form>
          <v-row>
            <v-col cols="12">
              <v-form ref="addTrainer">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      hide-detials="auto"
                      v-model="inputs.fullName"
                      :label="$t('name')"
                      outlined
                      :rules="[(v) => !!v || $t('this-field-is-requird')]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      hide-detials="auto"
                      v-model="inputs.email"
                      :label="$t('email')"
                      outlined
                      type="email"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      hide-detials="auto"
                      v-model="inputs.phoneNumber"
                      :label="$t('phone-number-0')"
                      dir="ltr"
                      v-mask="'+964 ### ### ####'"
                      :rules="[(v) => !!v || $t('this-field-is-requird')]"
                      outlined
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="12">
                    <v-text-field
                      hide-detials="auto"
                      v-model="inputs.salary"
                      :label="$t('salary')"
                      @input="
                        inputs.salary = $service.formatCurrency(inputs.salary)
                      "
                      placeholder="0.0"
                      :pattern="/^-?\d+\.?\d*$/"
                      suffix="IQD"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12" md="12">
                    <v-row
                      align="center"
                      justify="start"
                      class="d-flex align-center"
                      v-for="(attach, i) in inputs.userAttachement"
                      :key="i"
                    >
                      <v-col cols="5">
                        <v-file-input
                          v-model="inputs.userAttachement[i].path"
                          label="Attachement File"
                          chips
                          outlined
                          :disabled="filesLoading"
                          :loading="filesLoading"
                        ></v-file-input>
                      </v-col>
                      <v-col cols="5">
                        <v-text-field
                          hide-detials="auto"
                          v-model="inputs.userAttachement[i].name"
                          label="Attachement Name"
                          outlined
                          :disabled="filesLoading"
                          :loading="filesLoading"
                        ></v-text-field>
                      </v-col>
                      <v-col class="" cols="2">
                        <div class="d-flex mb-6">
                          <v-btn
                            @click="addToList"
                            :disabled="filesLoading"
                            :loading="filesLoading"
                            color="primary"
                            text
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                          <v-btn
                            v-if="i > 0"
                            @click="removeFromList(i)"
                            color="error"
                            text
                            :disabled="filesLoading"
                            :loading="filesLoading"
                          >
                            <f-icon icon="times-circle" />
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col> -->
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <div class="d-flex align-center justify-end py-3 px-3">
        <v-btn
          color="error"
          class="mx-1"
          :loading="loading"
          :disabled="loading"
          outlined
          @click="dialog = !dialog"
        >
          <f-icon icon="times-circle" />
          {{ $t('cancel') }}</v-btn
        >
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="loading"
          @click="addTrainer"
        >
          <v-icon>mdi-plus</v-icon>
          {{ $t('save') }}</v-btn
        >
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  computed: {
    isEdit() {
      return this.$store.state.managers.isEdit;
    },
  },
  data() {
    return {
      loading: false,
      companies: [],
      types: [],
      filesLoading: false,
      isPasswordShow: false,
      inputs: {
        fullName: "",
        email: "",
        phoneNumber: "",
        enrolledDate: new Date(),
        salary: 0,
        imageUrl: "",
        officeId: this.$store.state.userInfo.office.id,
        // userAttachement: [
        //   {
        //     name: "",
        //     path: null,
        //     type: "",
        //   },
        // ],
      },
    };
  },
  created() {
    // this.$http
    //   .get("/Managers/managerTypes")
    //   .then((res) => {
    //     this.types = res.data.result;
    //     this.$http.get("/Companies",{
        //   params:{
        //     OfficeId: this.$store.state.userInfo.office.id
        //   }
        // }).then((com) => {
    //       this.companies = com.data.result;
    //       this.loading = false;
    //       // this.checkIsEdit();
    //     });
    //   })
    //   .catch(() => {
    //     this.$store.commit("UPDATE_SNACKBAR", true);
    //   });
  },
  mounted() {
    // this.$http
    //   .get("/Managers/managerTypes")
    //   .then((res) => {
    //     this.types = res.data.result;
    //     this.$http.get("/Companies",{
        //   params:{
        //     OfficeId: this.$store.state.userInfo.office.id
        //   }
        // }).then((com) => {
    //       this.companies = com.data.result;
    //       this.loading = false;
    //       // this.checkIsEdit();
    //     });
    //   })
    //   .catch(() => {
    //     this.$store.commit("UPDATE_SNACKBAR", true);
    //   });
  },
  props: ["dialog"],
  methods: {
    addToList() {
      this.inputs.userAttachement.push({
        name: "",
        path: null,
        type: "",
      });
    },
    removeFromList(index) {
      this.inputs.userAttachement = this.inputs.userAttachement.splice(
        index,
        1
      );
    },
    async addTrainer() {
      var val = this.$refs.addTrainer.validate();
      if (val) {
        var body = this.inputs
       
        body.salary = body.salary.toString().replace(/,/g, "");
        
        // if (
        //     body.userAttachement[0].path != "" &&
        //     body.userAttachement[0].path != null
        //   ) {
        //     this.filesLoading = true;
        //     var form = new FormData();
        //     body.userAttachement.forEach((attach) => {
        //       form.append("files", attach.path);
        //     });

        //     var img = await this.$http.post(
        //       "/UploadFile/postUploadFiles",
        //       form
        //     );
        //     img.data.fileList.forEach((a, i) => {
        //       body.userAttachement[i].path = a.path;
        //       body.userAttachement[i].type = "attachment";
        //     });
        //   } else {
        //     delete body.userAttachement;
        //   }
        this.loading = true;
        this.$http
          .post("/Employees/createTrainer", body)
          .then((res) => {
            if (res.data.statusCode == 201) {
              this.dialog = false;
            }
          })
          .catch((e) => {
            this.$store.commit("UPDATE_SNACKBAR", true);
            this.$store.commit("UPDATE_SNACKBAR_MESSAGE", e.response.data.message + "<br />" +  typeof e.response.data.errors == 'array' ? e.response.data.errors.join(' ') : '');
            if(this.inputs.userAttachement == undefined){
              this.inputs.userAttachement = []
this.addToList()
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  watch: {
    dialog() {
      if (this.dialog != true) {
        this.$store.commit("managers/updateManager", {});
        this.$store.commit("managers/toggleEdit", false);
        if (this.$refs.addTrainer !== undefined) {
          this.$refs.addTrainer.reset();
        }
        this.filesLoading = false;
        this.$emit("updated", this.dialog);
      } else {
        // this.checkIsEdit();
      }
    },
  },
};
</script>
<style scoped>
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  margin: 0px !important;
}
.v-input__slot {
  margin-bottom: 0px !important;
  padding: 0px !important;
}
</style>