<template>
  <div>
    <add-manager
      @updated="
        (_) => {
          dialog = _;
          getManagers();
        }
      "
      :dialog="dialog"
    />
    <v-card :loading="loading" outlined class="transparent">
      <v-card-title>
        <h1 class="mb-3">{{ $t('managers') }}</h1>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = !dialog" large color="secondary">
          <v-icon>mdi-plus-circle</v-icon>
          {{ $t('add') }} {{ $t('managers') }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row class="mt-4">
          <v-col cols="4">
            <label for="se">{{ $t('search') }}</label>
            <v-text-field
              hide-detials="auto"
              class="mt-2"
              :label="$t('search')"
              id="se"
              solo
              :loading="loading"
              clearable
              v-model="options.Search"
              append-icon="mdi-magnify"
              type="search"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <label for="comp">{{ $t('select-company') }}</label>
            <v-autocomplete
              class="mt-2"
              v-model="options.CompanyId"
              :items="companies"
              :loading="loading"
              item-text="name"
              :label="$t('select-company')"
              item-value="id"
              :no-data-text="$t('no-data')"
              solo
              @change="getManagers()"
              hide-details="auto"
            />
          </v-col>

          <v-col cols="12">
            <v-data-table
              disable-pagination
              :loading="loading"
              :headers="headers"
              :items="managers"
              :no-data-text="$t('no-data')"
              :hide-default-footer="true"
            >
              <template v-slot:item.created="{ item }">
                {{ formatDate(new Date(item.created)) }}
              </template>
              <template v-slot:item.phoneNumber="{ item }">
                <div dir="ltr">
                  {{ item.phoneNumber }}
                </div>
              </template>
              <template v-slot:item.salary="{ item }">
                {{ $service.formatCurrency(item.salary) }} 
              </template>
              <template v-slot:item.view="{ item }">
                <v-btn
                  small
                  color="primary"
                  text
                  :to="{
                    name: 'hr-user',
                    params: { id: item.id, type: 'Managers' },
                  }"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <template v-slot:item.edit="{ item }">
                <v-btn small color="primary" text @click="editItem(item)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>

                <v-btn
                  @click="toggleActivation(item)"
                  text
                  :color="item.isActive ? 'error' : 'success'"
                >
                  <v-icon>
                    {{ item.isActive ? "mdi-close" : "mdi-check" }}
                  </v-icon>
                  {{ item.isActive ? $t('deactivate') : $t('activate') }}
                </v-btn>
                <v-btn @click="openChangePassword(item)" color="primary">
                  <v-icon>mdi-lock</v-icon>
                  {{ $t('change-password') }}
                </v-btn>
              </template>
            </v-data-table>
            <div class="d-flex justify-center">
              <v-pagination
                v-model="options.PageIndex"
                :length="parseInt(total / options.PageSize) + 1"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="changePasswordDialog" max-width="600" class = "editPassword">
      <v-card>
        <v-card-title>
          {{ $t('change-password') }} For {{ toChange.fullName }}
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="changePasswordDialog = !changePasswordDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-card-text>
            
            <v-text-field
              outlined
              :label="$t('new-password')"
              v-model="newPassword"
              type="text"
            ></v-text-field>
            <div class="d-flex justify-end">
              <v-btn
                color="error"
                outlined
                @click="changePasswordDialog = !changePasswordDialog"
              >
                <v-icon>mdi-close</v-icon>
                {{ $t('cancel') }}
              </v-btn>
              <v-btn color="primary" @click="changePassword">
                <v-icon>mdi-check</v-icon>
                {{ $t('save') }}
              </v-btn>
            </div>
          </v-card-text>
        </v-card-title>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { formatDate } from "../../../../utils";
// import EditUser from "../EditDialog.vue";
import AddManager from "@/views/modals/AddManager";
export default {
  components: {
    AddManager,
    // EditUser
  },
  methods: {
    formatDate,
    async changePassword() {
      this.loading = true;
      await this.$http.post(
        "/users/changePasswordByAdmin/" + this.toChange.id,
        {
          newPassword: this.newPassword,
          confirmPassword: this.newPassword,
        }
      );
      this.loading = false;
    },
    openChangePassword(item) {
      this.toChange = item;
      this.changePasswordDialog = true;
    },
    async toggleActivation(item) {
      try {
        this.loading = true;
        await this.$http.delete("/users/toggleActivation/" + item.id);
        this.getManagers();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    editItem(item) {
      let temp = item;
      this.$store.commit("users/updateItem", temp);
      this.$store.commit("users/toggleEdit");
      this.dialog = true;
    },
    getManagers() {
      this.loading = true;
      let filter =
        "?" +
        Object.entries(this.options)
          .map(([key, val]) => {
            if (val !== "" && val !== null) {
              return `${key}=${val}`;
            }
          })
          .join("&");
      this.$http.get("/Managers" + filter.replace("?&", "?")).then((res) => {
        this.total = res.data.count;
        this.managers = res.data.data;
        this.loading = false;
      });
    },
  },
  data() {
    return {
      changePasswordDialog: false,
      toChange: {},
      newPassword: "",
      companies: [],
      company: {},
      options: {
        Search: "",
        CompanyId: "",
        PageIndex: 1,
        PageSize: 10,
      },
      total: 0,
      headers: [
        {
          text: this.$t('name'),
          align: "start",
          sortable: false,
          value: "fullName",
        },
        {
          text: this.$t('username'),
          sortable: true,
          value: "userName",
        },
        {
          text: this.$t('email'),
          sortable: true,
          value: "email",
        },
        {
          text: this.$t('phone-number'),
          sortable: true,
          value: "phoneNumber",
        },
        {
          text: this.$t('company'),
          sortable: true,
          value: "companyName",
        },
        {
          text: this.$t('type'),
          sortable: true,
          value: "userTypeName",
        },
        {
          text: this.$t('salary'),
          sortable: true,
          value: "salary",
        },
        {
          text: this.$t('created'),
          sortable: true,
          value: "created",
        },
        {
          text: this.$t('edit'),
          sortable: true,
          value: "edit",
        },
        // {
        //   text: "More",
        //   sortable: false,
        //   value: "view",
        // },
      ],
      loading: true,
      managers: [],
      dialog: false,
    };
  },
  created() {
    this.$http
      .get("/Companies", {
        params: {
          OfficeId: this.$store.state.userInfo.office.id,
        },
      })
      .then((res) => {
        this.companies = res.data.result;
        this.company = this.companies[0];
        this.options.CompanyId = this.$store.state.company.id;
        this.getManagers();
      });
  },
  watch: {
    options: {
      handler() {
        this.getManagers();
      },
      deep: true,
    },
  },
};
</script>
<style>

.editPassword .v-dialog:not(.v-dialog--fullscreen){
  width: 40%;
}

</style>