<template>
  <div>
    <h1 class="mb-3">Paid Salaries</h1>
    <v-card :loading="loading" class="transparent">
      <v-row class="">
        <v-col cols="4">
          <label for="se">{{ $t('search') }}</label>
          <v-text-field
            hide-detials="auto"
            class="mt-2"
            :label="$t('search')"
            id="se"
            solo
            clearable
            v-model="options.Search"
            append-icon="mdi-magnify"
            type="search"
          ></v-text-field>
        </v-col>
        <v-col cols="4" 
          ><label for="comp">Select User</label>
          <v-autocomplete
            class="mt-2"
            clearable
            v-model="options.UserId"
            :items="users"
            :search-input.sync="searchUser"
            :loading="loading"
            :no-data-text="
              searchUser == '' && searchUser == null
                ? 'Write for search'
                : 'No data'
            "
            solo
            @change="getSalaries()"
            item-value="id"
            item-text="fullName"
            label="Select User"
          />
        </v-col>
        <v-col cols="12">
          <v-card class="transparent">
            <v-card-title>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text>
              <v-data-table
                disable-pagination
                :loading="loading"
                :headers="headers"
                :items="salaries"
                :no-data-text="$t('no-data')"
                :hide-default-footer="true"
              >
                <template v-slot:item.created="{ item }">
                  {{ $service.formatDate(new Date(item.created)) }}
                </template>
                <template v-slot:item.isPaid="{ item }">
                  {{ item.isPaid ? "Yes" : "No" }}
                </template>
              </v-data-table>
              <div class="d-flex justify-center">
                <v-pagination
                  v-model="options.PageIndex"
                  :length="parseInt(total / options.PageSize) + 1"
                />
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      loading: false,
      options: {
        Search: "",
        CompanyId: this.$store.state.company.id,
        OfficeId: this.$store.state.userInfo.office.id,
        UserId: "",
        IsPaid: true,
        PageIndex: 1,
        PageSize: 10,
      },
      total: 0,
      companies: [],
      company: {},
      searchUser: "",
      users: [],
      salaryCalculation: false,
      salaries: [],
      headers: [
        {
          text: this.$t('user'),
          align: "start",
          sortable: false,
          value: "userName",
        },
        { text: "Salary", value: "defaultSalary" },
        // { text: "تم دفعه", value: "amountPaid" },
        { text: "Incentives", value: "totalIncentives" },
        { text: "Deductions", value: "totalDeductions" },
        { text: "Drawn Amounts", value: "totalWithdrawnAmounts" },
        { text: "Transfer", value: "totalTransferReceivables" },
        { text: "Total", value: "totalSalary" },
        { text: "Paid", value: "isPaid" },
        { text: "Date", value: "created" },
        { text: "", value: "pay", sortable: false },
      ],
    };
  },
  methods: {
    getSalaries() {
      this.loading = true;
      let filter =
        "?" +
        Object.entries(this.options)
          .map(([key, val]) => {
            if (val !== "" && val !== null && val !== undefined) {
              return `${key}=${val}`;
            }
          })
          .join("&");
      this.$http
        .get("/Salaries" + filter.replace("?&", "?"))
        .then((salaries) => {
          this.total = salaries.data.count;
          this.salaries = salaries.data.data;
          for (let i = 0; i < this.salaries.length; i++) {
            Object.keys(this.salaries[i]).forEach((key) => {
              if (typeof this.salaries[i][key] == "number") {
                this.salaries[i][key] =
                  "IQD " + this.$service.formatCurrency(this.salaries[i][key]);
              }
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.$store.commit("UPDATE_SNACKBAR", true);
        });
    },
  },
  watch: {
    options: {
      handler() {
        this.getSalaries();
      },
    },
    searchUser() {
      if (this.searchUser !== "" && this.searchUser !== null) {
        this.loading = true;
        this.$http
          .get("/Users?Search=" + this.searchUser, {
            params: {
              OfficeId: this.$store.state.userInfo.office.id,
            },
          })
          .then((res) => {
            this.users = res.data.data;
            this.loading = false;
          });
      }
    },
  },
  async created() {
    this.$http
      .get("/Companies", {
        params: {
          OfficeId: this.$store.state.userInfo.office.id,
        },
      })
      .then((res) => {
        this.companies = res.data.result;
        this.getSalaries();
      })
      .catch(() => {
        this.$store.commit("UPDATE_SNACKBAR", true);
      });
  },
};
</script>
