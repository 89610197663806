<template>
  <div>
    <add-accounts
      @updated="
        (_) => {
          dialog = _;
          getAccounts();
        }
      "
      :dialog="dialog"
    />
    <v-card :loading="loading" outlined class="transparent">
      <v-card-title>
        <h1 class="mb-3">Accounts</h1>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = !dialog" large color="secondary">
          <v-icon>mdi-plus-circle</v-icon>
          Add Accounts
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row class="mt-4">
          <v-col cols="4">
            <label for="se">{{ $t('search') }}</label>
            <v-text-field
              hide-detials="auto"
              class="mt-2"
              :label="$t('search')"
              id="se"
              solo
              :loading="loading"
              clearable
              v-model="options.Search"
              append-icon="mdi-magnify"
              type="search"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-data-table
              disable-pagination
              :loading="loading"
              :headers="headers"
              :items="delivery"
              :no-data-text="$t('no-data')"
              :hide-default-footer="true"
            >
              <template v-slot:item.phoneNumber="{ item }">
                <div dir="ltr">
                  {{ item.phoneNumber }}
                </div>
              </template>
              <template v-slot:item.salary="{ item }">
                {{ $service.formatCurrency(item.salary) }} 
              </template>
              <template v-slot:item.created="{ item }">
                <span class="text-no-wrap">
                  {{ formatDate(new Date(item.created)) }}
                </span>
              </template>
              <template v-slot:item.view="{ item }">
                <v-btn
                  small
                  color="primary"
                  text
                  :to="{
                    name: 'hr-user',
                    params: { id: item.id, type: 'Delivery' },
                  }"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <template v-slot:item.edit="{ item }">
                <v-btn small color="primary" text @click="editItem(item)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  @click="toggleActivation(item)"
                  text
                  :color="item.isActive ? 'error' : 'success'"
                >
                  <v-icon>
                    {{ item.isActive ? "mdi-close" : "mdi-check" }}
                  </v-icon>
                  {{ item.isActive ? $t('deactivate') : $t('activate') }}
                </v-btn>
                <v-btn @click="openChangePassword(item)" color="primary">
                  <v-icon>mdi-lock</v-icon>
                  {{ $t('change-password') }}
                </v-btn>
              </template>
            </v-data-table>
            <div class="d-flex justify-center">
              <v-pagination
                v-model="options.PageIndex"
                :length="parseInt(total / options.PageSize) + 1"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="changePasswordDialog"
      max-width="600"
      class="editPassword"
    >
      <v-card>
        <v-card-title>
          {{ $t('change-password') }} For {{ toChange.fullName }}
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="changePasswordDialog = !changePasswordDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-card-text>
            <v-text-field
              outlined
              label="New Password"
              v-model="newPassword"
              type="text"
            ></v-text-field>
            <div class="d-flex justify-end">
              <v-btn
                color="error"
                outlined
                @click="changePasswordDialog = !changePasswordDialog"
              >
                <v-icon>mdi-close</v-icon>
                Cancel
              </v-btn>
              <v-btn color="primary" @click="changePassword">
                <v-icon>mdi-check</v-icon>
                Save
              </v-btn>
            </div>
          </v-card-text>
        </v-card-title>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { formatDate } from "../../../../utils";
import AddAccounts from "@/views/modals/AddAccounts.vue";

export default {
  components: {
    AddAccounts,
  },
  methods: {
    formatDate,
    openChangePassword(item) {
      this.toChange = item;
      this.changePasswordDialog = true;
    },
    async changePassword() {
      this.loading = true;
      await this.$http
        .post("/users/changePasswordByAdmin/" + this.toChange.id, {
          newPassword: this.newPassword,
          confirmPassword: this.newPassword,
        })
        .then(() => {
          console.log("go");
          this.changePasswordDialog = false;
          this.loading = false;
        });
    },
    async toggleActivation(item) {
      try {
        this.loading = true;
        await this.$http.delete("/users/toggleActivation/" + item.id);
        this.getAccounts();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    editItem(item) {
      let temp = item;
      this.$store.commit("users/updateItem", temp);
      this.$store.commit("users/toggleEdit");
      this.dialog = true;
    },
    getAccounts() {
      this.loading = true;
      let filter = Object.entries(this.options)
        .map(([key, val]) => {
          if (val !== "" && val !== null) {
            return `${key}=${val}`;
          }
        })
        .join("&");
      this.$http
        .get("/users?" + filter)
        .then((res) => {
          this.total = res.data.count;
          this.delivery = res.data.data;
          this.loading = false;
        })
        .catch((e) => {
          this.$store.commit("UPDATE_SNACKBAR", true);
          this.$store.commit(
            "UPDATE_SNACKBAR_MESSAGE",
            e.response.data.message
          );
        });
    },
  },
  data() {
    return {
      changePasswordDialog: false,
      toChange: {},
      newPassword: "",
      companies: [],
      company: {},
      options: {
        Search: "",
        OfficeId: this.$store.state.userInfo.office.id,
        UserTypes: "Ac",
        PageIndex: 1,
        PageSize: 10,
      },
      total: 0,
      headers: [
        {
          text: this.$t('name'),
          align: "start",
          sortable: false,
          value: "fullName",
        },
        {
          text: "Username",
          sortable: true,
          value: "userName",
        },
        {
          text: "Email",
          sortable: true,
          value: "email",
        },
        {
          text: "Phone Number",
          sortable: true,
          value: "phoneNumber",
        },
        {
          text: "Type",
          sortable: true,
          value: "userTypeName",
        },
        {
          text: "Salary",
          sortable: true,
          value: "salary",
        },
        {
          text: this.$t('created'),
          sortable: true,
          value: "created",
        },
        {
          text: "",
          sortable: true,
          value: "edit",
        },
        // {
        //   text: "More",
        //   sortable: false,
        //   value: "view3",
        // },
      ],
      loading: true,
      delivery: [],
      deliveryTypes: [],
      dialog: false,
    };
  },
  created() {
    this.getAccounts();
  },
  watch: {
    options: {
      handler: function () {
        this.getAccounts();
      },
      deep: true,
    },
  },
};
</script>
